<template>
  <div class="content">
    <base-header />
    <main class="wrapper">
      <NuxtPage />
    </main>
    <base-footer />
    <div v-if="useUserStore.role === 'admin'" class="admin-mode">
      You're in admin mode. All info on pages might look different than for
      default users. Please mind that.
    </div>
  </div>
  <Teleport to="body">
    <noscript>
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-WPMLJH26"
        height="0"
        width="0"
        style="display: none; visibility: hidden"
      ></iframe>
    </noscript>
  </Teleport>
</template>

<script setup lang="ts">
import { PopUpServices } from "~/services/PopUp/callPopUp";
import { getLocalStorage } from "~/services/LocalStorage/localStorage";
import { userStore } from "~/store/user";
import { sendMessageToBot } from "~/plugins/error-handler";

const callPopUp = PopUpServices();
const useUserStore = userStore();
const $q = useQuasar();
const nuxtApp = useNuxtApp();
const route = useRoute();

const checkCookie = () => {
  const excludePaths = ["/cookies-policy", "/terms", "/privacy-policy"];
  if (excludePaths.includes(route.path)) return;
  const isCookie = getLocalStorage("cookie_agreement");
  if (!isCookie) {
    callPopUp.callPopUp({
      componentName: "base-cookie-pop-up",
      persistent: true,
    });
  }
};

const checkUser = async () => {
  const token = useCookie("acc_token");
  if (!token.value) return;
  const res = await useUserStore.checkAccTokenExp(token.value);
  if (!res) return;
  if (route.path === "/profile") return;
  useUserStore.fetchUserData();
};

nuxtApp.hooks.hookOnce("page:start", async () => {
  const { proxy } = useScriptHotjar({
    id: 5060044,
  });
  proxy.hj("identify", 5060044, {});
  if (route.path === "/directory") return;
  $q.loading.show();
});

nuxtApp.hooks.hookOnce("page:finish", () => {
  setTimeout(() => {
    $q.loading.hide();
  }, 1000);
});

onMounted(() => {
  checkCookie();
  checkUser();
  useUserStore.createUserUID();
});
</script>

<style scoped lang="scss">
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--white-monochrome);
  .admin-mode {
    position: fixed;
    top: 30px;
    left: 30px;
    padding: 10px;
    background-color: var(--violet-40);
    border-radius: 10px;
    opacity: 0.3;
    max-width: 150px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
</style>
